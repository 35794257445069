import React, { useState } from "react";
import ModalComponent from "../../../../components/Modals/modal";
import { useDispatch } from "react-redux";
import { farnersGeneratePin } from "../../../../redux/Farmers/farmersActions";

const GeneratePin = ({ phoneNumber }) => {
  const dispatch = useDispatch();
  const [isClickGeneratePin, setIsClickGeneratePin] = useState(false);

  const handleGeneratePin = () => {
    dispatch(
      farnersGeneratePin({
        value: phoneNumber,
      })
    );

    setTimeout(() => {
      setIsClickGeneratePin(false);
    }, 800);
  };

  return (
    <div>
      {" "}
      <p
        className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
        onClick={() => setIsClickGeneratePin(true)}
      >
        Generate Pin
      </p>
      {/* GENERATE PIN MODAL */}
      <ModalComponent
        title="Generate Default Pin"
        show={isClickGeneratePin}
        showModal={() => setIsClickGeneratePin(false)}
        subtitle="Are you sure you sure you want to generate a default pin?"
      >
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <p
            className="text-sm text-medium leading-5 text-[#5C715E]"
            onClick={() => setIsClickGeneratePin(false)}
          >
            No
          </p>
          <button
            className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white"
            onClick={handleGeneratePin}
          >
            Yes
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default GeneratePin;
