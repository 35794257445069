import React, { useState } from "react";
import GoBack from "../../components/GoBack";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import archieve from "../../assets/icons/archive.svg";
import stop from "../../assets/icons/stop.svg";
import pause from "../../assets/icons/pause.svg";
import active from "../../assets/icons/fiber_manual_record.svg";

import {
  getCampaignSummary,
  getCampaignSummaryStats,
  updateCampaignSummary,
} from "../../redux/Campaign/campaignAction";
import moment from "moment";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Archive, FiberManualRecord, Pause, Stop } from "@mui/icons-material";

const CampaignSummary = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { campaignSummaryStatsData, campaignSummaryData } = useSelector(
    (state) => state.campaign
  );
  const [clickStatus, setClickStatus] = useState("");

  const statuses = [
    {
      name: "Active",
      icon: active,
      color: "#2B8C34",
      key: "Active",
    },
    {
      name: "Pause",
      icon: pause,
      color: "#d8a600",
      key: "Inactive",
    },
    {
      name: "End",
      icon: stop,
      color: "#B92043",
      key: "Ended",
    },
    {
      name: "Archive",
      icon: archieve,
      color: "#344335",
      key: "Archive",
    },
  ];

  console.log(campaignSummaryStatsData);

  const handleUpdateStatus = (data) => {
    setClickStatus(false);
    dispatch(updateCampaignSummary({ id: id, value: data }));

    setTimeout(() => {
      dispatch(getCampaignSummary({ id: id }));
      dispatch(getCampaignSummaryStats({ id: id }));
    }, 800);
  };

  useEffect(() => {
    dispatch(getCampaignSummary({ id: id }));
    dispatch(getCampaignSummaryStats({ id: id }));
  }, [dispatch, id]);

  return (
    <div className="">
      {" "}
      <div className="mb-4">
        <GoBack />
      </div>
      {/* HEDAER SECTION */}
      <section className="mb-5 ">
        <Header text="Campaign Summary">
          <div className="flex gap-5 relative">
            <div className="">
              <div
                onClick={() => setClickStatus(campaignSummaryStatsData?.status)}
                className={` ${
                  campaignSummaryStatsData?.status === "Active"
                    ? "bg-primary"
                    : campaignSummaryStatsData?.status === "Inactive"
                    ? "bg-[#D8A600]"
                    : campaignSummaryStatsData?.status === "Ended"
                    ? "bg-[#B92043]"
                    : campaignSummaryStatsData?.status === "Archive"
                    ? "bg-[#344335]"
                    : null
                }  flex justify-center items-center w-[106px] h-[40px] rounded-[8px] cursor-pointer gap-2 text-white text-sm font-medium leading-5`}
              >
                <>
                  {campaignSummaryStatsData?.status === "Active" ? (
                    <FiberManualRecord style={{ fontSize: "20px" }} />
                  ) : campaignSummaryStatsData?.status === "Inactive" ? (
                    <Pause style={{ fontSize: "20px" }} />
                  ) : campaignSummaryStatsData?.status === "Ended" ? (
                    <Stop style={{ fontSize: "20px" }} />
                  ) : campaignSummaryStatsData?.status === "Archive" ? (
                    <Archive style={{ fontSize: "20px" }} />
                  ) : null}
                </>
                <p> {campaignSummaryStatsData?.status}</p>
              </div>

              {clickStatus && (
                <div
                  className="absolute rounded-[8px] p-[8px] w-auto mt-[3%] bg-white z-10"
                  style={{
                    boxShadow:
                      "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                  }}
                >
                  {statuses.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex items-center gap-4"
                        onClick={() => {
                          handleUpdateStatus(data.key);
                        }}
                      >
                        <img src={data.icon} alt={data.name} className="" />
                        <p className="font-medium text-sm leading-5 text-[#344335] capitalize">
                          {data.name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="cursor-pointer flex justify-center items-center font-medium text-sm leading-5 bg-[#EDF7EE] text-primary rounded-[8px] w-[133px] h-[40px] ">
              <Link to={`/edit-campaign/${id}`}>
                <p>Edit Campaign</p>
              </Link>
            </div>
          </div>
        </Header>
      </section>
      <div className="flex gap-5 h-[90vh]">
        <div className="w-[75%] campaignScroll overflow-x-scroll">
          <div>
            {/* 1 */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Campaign info
              </p>

              {/* CAMPAIGN NAME */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Campaign Name
                </p>
                <input
                  type="text"
                  className="outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder="Campaign Name"
                  disabled
                  value={campaignSummaryData?.campaign_name}
                />
              </div>

              {/* CAMPAIGN DESCRIPTION */}
              <div className="mb-4">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Campaign Description
                </p>
                <textarea
                  className="resize-none h-[134px] outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                  placeholder="Campaign Description"
                  disabled
                  value={campaignSummaryData?.description}
                />
              </div>
            </div>

            {/* 4(STATE AND LOCAL GOVERNMENT) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Campaign Location
              </p>

              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                State(s) and Local Government
              </p>

              <div className="mb-2 text-medium text-sm leading-[18px] text-[#96a397] pt-4">
                {campaignSummaryData?.state_lga?.map((data, index) => {
                  return (
                    <div key={index} className="flex flex-row w-full flex-wrap">
                      {data.state}(
                      {data?.lgas.map((lgaData, index) => {
                        return (
                          <div key={index} className="flex flex-row">
                            {lgaData},
                          </div>
                        );
                      })}
                      )
                    </div>
                  );
                })}
              </div>
            </div>

            {/* 5(CROPS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Crop
              </p>

              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Crop Type
              </p>

              <div className="mb-2 text-medium text-sm leading-[18px] text-[#96a397] pt-4">
                {campaignSummaryData?.crops?.map((data, index) => {
                  return (
                    <div key={index} className="flex flex-row w-full flex-wrap">
                      {data}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* 6(STATUS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Account Status
              </p>

              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Active Status
              </p>

              <div className="flex gap-4">
                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    From
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {!campaignSummaryData?.active_status?.from
                        ? "N/A"
                        : moment(
                            campaignSummaryData?.active_status?.from
                          ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    To
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {" "}
                      {!campaignSummaryData?.active_status?.to
                        ? "N/A"
                        : moment(campaignSummaryData?.active_status?.to).format(
                            "DD/MM/YYYY"
                          )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* 7(TRANSACTION STATUS) */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E] pb-[19px]">
                Transaction Status
              </p>

              <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                Transaction Status
              </p>

              <div className="flex gap-4">
                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    From
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {!campaignSummaryData?.transaction_status?.from
                        ? "N/A"
                        : moment(
                            campaignSummaryData?.transaction_status?.from
                          ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="py-1 leading-[18px] font-medium text-sm text-[#abb6ac]">
                    To
                  </p>
                  <div className="w-[162px] flex justify-between h-[44px] bg-[#f7f7f7] border-[#abb6ac] border border-solid rounded-[8px] px-[12px] items-center font-medium text-sm leading-5 text-[#abb6ac]">
                    {/* FROM */}
                    <p>
                      {" "}
                      {!campaignSummaryData?.transaction_status?.to
                        ? "N/A"
                        : moment(
                            campaignSummaryData?.transaction_status?.to
                          ).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* LANGUAGE */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E]">
                Language
              </p>

              <div className="flex gap-4 mb-2 text-medium text-sm leading-[18px] text-[#96a397] pt-4">
                {campaignSummaryData?.languages?.map((data, index) => {
                  return (
                    <div key={index} className="">
                      {data}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* AGENTS */}
            <div className="rounded-[8px] bg-white p-5 pr-[100px] mb-4 ">
              <p className="font-semibold text-sm leading-[18px] text-[#7C8D7E]">
                Agents
              </p>
              <div className="flex flex-wrap gap-4 mb-2 text-medium text-sm leading-[18px] text-[#96a397] pt-4">
                {campaignSummaryData?.agents?.map((data, index) => {
                  return (
                    <div key={index} className="">
                      {data}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* FLEX 2 */}
        <div className="w-[25%]">
          <div className="bg-white rounded-[8px] p-6">
            <p className="font-semibold text-sm text-[#7C8D7E] leading-[18px] pb-1">
              Campaign Summary
            </p>
            <p className="font-[400] text-xs text-[#5C715E] leading-[18px] pb-4">
              To see the summary of a campaign.
            </p>

            <div>
              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Total Farmers:{" "}
                  <span className="text-primary">
                    {campaignSummaryStatsData?.total_farmers}
                  </span>
                </p>
              </div>

              {/* 2 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Calls Made:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.calls_made}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Follow Ups Created:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.follow_ups_created}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Sales Made:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.sales_made}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Running Time:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.running_time?.days}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Campaign Status:{" "}
                  <span className="text-primary">
                    {" "}
                    {campaignSummaryStatsData?.status}
                  </span>
                </p>
              </div>

              {/* 1 */}
              <div className="flex items-center gap-[9px] mb-[10px]">
                <p className="font-medium text-sm leading-5 text-[#344335]">
                  Date Created:{" "}
                  <span className="text-primary">
                    {" "}
                    {moment(campaignSummaryStatsData?.created).format("lll")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSummary;
