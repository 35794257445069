import { ExpandLess, ExpandMore, MoreVert } from "@mui/icons-material";
import React, { useState, useRef, createContext, useEffect } from "react";
import { ButtonPrimary, ButtonSecondary } from "../../../components/Button";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import { useOutsideAlerter } from "../../../helpers/OutsideClick";
import BankTransactionTable from "../../../tables/Farmers/FarmersProfileTables/BankTransaction";
import CallHistoryTable from "../../../tables/Farmers/FarmersProfileTables/CallHistory";
import OrdersTable from "../../../tables/Farmers/FarmersProfileTables/Orders";
import RefundsTable from "../../../tables/Farmers/FarmersProfileTables/Refunds";
import UssdSessionTable from "../../../tables/Farmers/FarmersProfileTables/UssdSession";
import FarmerAccountInformation from "./FarmerAccountInformation";
import BlockFarmer from "../FarmersModals/BlockFarmer/index";
import SuspendFarmer from "../FarmersModals/SuspendFarmer/index";
import GeneratePin from "../FarmersModals/GeneratePin/index";
import GenerateStatement from "../FarmersModals/GenerateStatement/index";
import DailyLimit from "../FarmersModals/DailyLimit/index";
import { useNavigate, useParams } from "react-router-dom";
import Cart from "./Cart";
import CreateFarmerOrderModal from "./CreateFarmerOrderModal";
import ModalComponent from "../../../components/Modals/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessageTemplate,
  sendSingleMessage,
} from "../../../redux/Messages/messageActions";
import cogoToast from "cogo-toast";
import { getFarmersProfile } from "../../../redux/Farmers/farmersActions";
import LoanPortfolioTable from "../../../tables/Farmers/FarmersProfileTables/LoanPortfolio";
import UnsuspendFarmer from "../FarmersModals/UnsuspendFarmer";
import UnblockFarmer from "../FarmersModals/UnblockFarmer";
import ResetBVN from "../../Agents/FarmersModals/ResetBVN";

export const FarmerContext = createContext({
  isClickMore: false,
  setIsClickMore: () => {},
});

const FarmersProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { templateData, sendMessageLoading } = useSelector(
    (state) => state.message
  );
  const { farmersProfileData } = useSelector((state) => state.farmer);
  const [tab, setTab] = useState("account_information");
  const [show, setShow] = useState(false);
  const [isClickMore, setIsClickMore] = useState(false);
  const value = { isClickMore, setIsClickMore };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsClickMore);

  ////////////////////////////////////////////// LANGUAGE
  const [toggleLanguageIcon, setToggleLanguageIcon] = useState(false);
  const [toggleTemplateIcon, setToggleTemplateIcon] = useState(false);
  const languageData = [
    { id: "eng", name: "English" },
    { id: "yor", name: "Yoruba" },
    { id: "hau", name: "Hausa" },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [message, setMessage] = useState("");

  const handleOpenLanguage = () => {
    if (Object.keys(selectedTemplate).length !== 0) {
      setToggleLanguageIcon(!toggleLanguageIcon);
    } else {
      cogoToast.error("Kindly select a template first");
    }
  };

  const handleSelectLanguage = (langData) => {
    let result = selectedTemplate.templates.filter(
      (data) => data.language === langData.name
    );
    setMessage(result.length !== 0 ? result[0].body : "");
    setSelectedLanguage(langData);
    setToggleLanguageIcon(false);
  };

  const handleSendMessage = () => {
    const value = {
      message,
      phone_numbers: [farmersProfileData?.phone_number],
    };

    dispatch(sendSingleMessage({ value }))
      .then((res) => {
        if (res?.payload?.error) {
          cogoToast.error(res?.payload?.error);
          setShow(false);
        } else {
          setMessage("");
          setSelectedLanguage({});
          setSelectedTemplate({});
          cogoToast.success("Message sent successfully");
          setShow(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
        cogoToast.error(error?.response?.data.error);
      });
  };

  useEffect(() => {
    if (show) {
      dispatch(getMessageTemplate({ page: 1 }));
      dispatch(getFarmersProfile({ id: id }));
    }
  }, [dispatch, show, id]);

  console.log(sendMessageLoading);

  return (
    <div>
      <div>
        <GoBack />
      </div>

      {/* HEADER AND TAB SECTION */}
      <div className="mt-[14px] mb-6">
        <Header
          text="Farmer Profile"
          tabs={
            <div className="flex gap-[16px] mt-[16px]">
              <p
                className={
                  tab === "account_information"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("account_information")}
              >
                Account Information
              </p>
              <p
                className={
                  tab === "order_history"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("order_history")}
              >
                Order History
              </p>
              <p
                className={
                  tab === "bank_transaction"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("bank_transaction")}
              >
                Bank Transaction
              </p>
              <p
                className={
                  tab === "ussd_sessions"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("ussd_sessions")}
              >
                USSD Sessions
              </p>
              <p
                className={
                  tab === "call_history"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("call_history")}
              >
                Call Request
              </p>
              <p
                className={
                  tab === "refunds"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("refunds")}
              >
                Refunds
              </p>
              {/* <p
                className={
                  tab === "cart"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("cart")}
              >
                Cart
              </p> */}
              <p
                className={
                  tab === "loan_porfolio"
                    ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                    : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                }
                onClick={() => setTab("loan_porfolio")}
              >
                Loan Portfolio
              </p>
            </div>
          }
        >
          <div className="relative">
            <div className="flex gap-[34px] items-center">
              <div className="flex gap-[16px]">
                <ButtonPrimary href="tel:555-555-5555">
                  Call Farmer
                </ButtonPrimary>
                <ButtonSecondary onclick={() => setShow(true)}>
                  Send Message
                </ButtonSecondary>
              </div>
              <div className="relative">
                <MoreVert
                  onClick={() => setIsClickMore(!isClickMore)}
                  className="text-primary cursor-pointer"
                  style={{ fontSize: "20px" }}
                />
              </div>
            </div>

            {isClickMore && (
              <FarmerContext.Provider value={value}>
                <div
                  className="absolute right-0 z-10 w-[213px] h-[fit-content] rounded-[8px] bg-white p-[8px] hover:rounded-[6px]"
                  style={{
                    boxShadow:
                      "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)",
                  }}
                  ref={wrapperRef}
                >
                  {/* EDIT PROFILE */}
                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <p
                      className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
                      onClick={() => navigate(`/edit-profile/${id}`)}
                    >
                      Edit Profile
                    </p>
                  </div>

                  {/* CRAETE ORDER */}
                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <CreateFarmerOrderModal id={id} />
                  </div>

                  {/* Generate Statment*/}
                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <GenerateStatement id={id} />
                  </div>

                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <p
                      className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
                      onClick={() => navigate(`/kyc-documentation/${id}`)}
                    >
                      KYC Documentation
                    </p>
                  </div>

                  {/* Daily Limit */}
                  {}
                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <DailyLimit id={id} />
                  </div>

                  {/* Generate Pin */}
                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <GeneratePin
                      phoneNumber={farmersProfileData?.phone_number}
                    />
                  </div>

                  {/* Suspend Farmer */}
                  {farmersProfileData?.is_shadow_banned ? (
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <UnsuspendFarmer id={id} />
                    </div>
                  ) : (
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <SuspendFarmer id={id} />
                    </div>
                  )}
                  {/* Block Farmer */}
                  {farmersProfileData?.is_restricted ? (
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <UnblockFarmer id={id} />
                    </div>
                  ) : (
                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <BlockFarmer id={id} />
                    </div>
                  )}

                  {/* ResetBVN*/}
                  <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                    <ResetBVN id={id} />
                  </div>
                </div>
              </FarmerContext.Provider>
            )}
          </div>
        </Header>
      </div>

      <div className="">
        {tab === "account_information" ? (
          <div>
            <FarmerAccountInformation id={id} />
          </div>
        ) : tab === "order_history" ? (
          <div>
            <OrdersTable />
          </div>
        ) : tab === "bank_transaction" ? (
          <div>
            <BankTransactionTable />
          </div>
        ) : tab === "ussd_sessions" ? (
          <div>
            <UssdSessionTable />
          </div>
        ) : tab === "call_history" ? (
          <div>
            <CallHistoryTable />
          </div>
        ) : tab === "refunds" ? (
          <div>
            <RefundsTable />
          </div>
        ) : tab === "cart" ? (
          <div>
            <Cart />
          </div>
        ) : tab === "loan_porfolio" ? (
          <div>
            <LoanPortfolioTable />
          </div>
        ) : null}
      </div>

      {/* SEND MESSAGE MODAL */}
      <ModalComponent
        title="Compose Message"
        show={show}
        showModal={() => setShow(false)}
      >
        {/* MESSAGE TEMPLATE */}
        <div className="pt-[16px] pb-[10px]">
          <div className="relative w-full">
            <p className="font-[400] text-sm leading-[18px] text-[#344335]">
              Message Template
            </p>
            <div
              className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[6px]"
              onClick={() => {
                setToggleTemplateIcon(!toggleTemplateIcon);
              }}
            >
              <p>{selectedTemplate.title || "Select a template"}</p>

              {toggleTemplateIcon ? (
                <ExpandLess
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              ) : (
                <ExpandMore
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              )}
            </div>

            {toggleTemplateIcon && (
              <div
                className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                style={{
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                }}
              >
                {templateData.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                      onClick={() => {
                        setSelectedTemplate(data);
                        setToggleTemplateIcon(false);
                      }}
                    >
                      <p className="font-medium text-sm leading-5 text-[#344335]">
                        {data.title}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* LANG */}
        <div className="pb-[10px]">
          <div className="relative w-full">
            <p className="font-[400] text-sm leading-[18px] text-[#344335]">
              Select Language
            </p>
            <div
              className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[6px]"
              onClick={handleOpenLanguage}
            >
              <p>{selectedLanguage.name || "Language"}</p>

              {toggleLanguageIcon ? (
                <ExpandLess
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              ) : (
                <ExpandMore
                  className="text-[#ABB6AC] cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              )}
            </div>

            {toggleLanguageIcon && (
              <div
                className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                style={{
                  boxShadow:
                    "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                }}
              >
                {languageData.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                      onClick={() => handleSelectLanguage(data)}
                    >
                      <p className="font-medium text-sm leading-5 text-[#344335]">
                        {data.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* MESSAGE */}
        <div className="pb-[10px]">
          <textarea
            className="resize-none h-[134px] disabled:bg-[#F7F7F7] outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
            placeholder="Message"
            onChange={(event) => setMessage(event.target.value)}
            value={message}
          />
        </div>

        <div className="flex items-center justify-end gap-[24px] my-6">
          <button
            type="submit"
            disabled={message.length === 0 || sendMessageLoading ? true : false}
            onClick={message && !sendMessageLoading ? handleSendMessage : null}
            className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
          >
            Send
          </button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default FarmersProfile;
