import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  getBankTransactions,
  getBankTransactionsSearch,
  getATransaction,
} from "../../redux/Transactions/transactionsAction";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import DrawerComponent from "../../components/Drawers/drawers";
import moment from "moment";

const BankTransactionsTable = () => {
  const dispatch = useDispatch();
  const [transactionsData, setTransactionsData] = useState({});
  const [isClickTable, setIsClickTable] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    bankTransactionsData,
    allTransactionsTotal,
    bankTransactionsLoading,
    transactionData,
    transactionLoading,
  } = useSelector((state) => state.transactions);

  const [typeID, setTypeID] = useState(1);
  const callTypes = [
    {
      id: 1,
      title: "All",
      tag: "all",
    },
    {
      id: 2,
      title: "Debit",
      tag: "debit",
    },
    {
      id: 3,
      title: "Credit",
      tag: "credit",
    },
  ]; 

  // ALL PAGE CHANGE
  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    dispatch(
      getBankTransactions({
        page: page,
        type: "all",
      })
    );
  };

  const toggleType = (id) => {
    setTypeID(id);
    switch (id) {
      case 1:
        dispatch(
          getBankTransactions({
            page: 1,
            type: "all",
          })
        );
        break;
      case 2:
        dispatch(
          getBankTransactions({
            page: 1,
            type: "debit",
          })
        );
        break;
      case 3:
        dispatch(
          getBankTransactions({
            page: 1,
            type: "credit",
          })
        );
        break;
      default:
        break;
    }
  };

  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        console.log(val);
        switch (typeID) {
          case 1:
            dispatch(
              getBankTransactionsSearch({
                value: val,
                type: "all",
                page: 1,
              })
            );
            break;
          case 2:
            dispatch(
              getBankTransactionsSearch({
                value: val,
                type: "debit",
                page: 1,
              })
            );
            break;
          case 3:
            dispatch(
              getBankTransactionsSearch({
                value: val,
                type: "credit",
                page: 1,
              })
            );
            break;
          default:
            break;
        }
        //  api to dispatch and make the actions for search
      }, 750),
    [dispatch, typeID]
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  const handleGetATransaction = (id, data) => {
    setTransactionsData(data);
    dispatch(
      getATransaction({
        id: id,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getBankTransactions({
        page: 1,
        type: "all",
      })
    );
  }, [dispatch]);

  return (
    <div>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="">
            <SearchComponent
              placeholder="Search..."
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {callTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : ""
                  }
                >
                  {typeID === item.id ? allTransactionsTotal : ""}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 pb-2">
                  TXN ID
                </th>
                <th scope="col" className="px-4 pb-2">
                  Name
                </th>
                <th scope="col" className="w-[130px] px-4 pb-2">
                  Activity
                </th>
                <th scope="col" className="px-4 pb-2">
                  Amount
                </th>
                <th scope="col" className="px-4 pb-2">
                  Date
                </th>
                <th scope="col" className="px-4 pb-2">
                  Time
                </th>
                <th scope="col" className="px-4 pb-2">
                  TXN Type
                </th>
                <th scope="col" className="px-4 pb-2">
                  TXN Status
                </th>
              </tr>
            </thead>
            {!bankTransactionsLoading && (
              <tbody>
                {bankTransactionsData && bankTransactionsData?.length > 0
                  ? bankTransactionsData?.map((data, index) => (
                      <tr
                        key={index}
                        className="relative cursor-pointer hover:bg-[#edf7ee]"
                        onClick={() => {
                          setIsClickTable(!isClickTable);
                          handleGetATransaction(data?.id, data);
                        }}
                      >
                        <td className="px-4">{data?.id}</td>
                        <td className="px-4">
                          {data?.farmer?.first_name +
                            " " +
                            data?.farmer?.last_name}
                        </td>
                        <td className="px-4 capitalize">
                          {data?.action?.replace("_", " ").replace("_", " ")}
                        </td>
                        <td className="px-4">N{data?.amount}</td>
                        <td className="px-4">
                          {moment(data?.created).format("ll")}
                        </td>
                        <td className="px-4">
                          {moment(data?.created).format("LT")}
                        </td>
                        <td className="px-4">
                          <div
                            className={
                              data?.transaction_type === "debit"
                                ? "error-badge"
                                : data?.transaction_type === "credit"
                                ? "success-badge"
                                : data?.transaction_type === "refund"
                                ? "success-badge"
                                : null
                            }
                          >
                            <p>
                              {" "}
                              {data?.transaction_type === "debit"
                                ? "Debit"
                                : data?.transaction_type === "credit"
                                ? "Credit"
                                : data?.transaction_type === "refund"
                                ? "Credit"
                                : null}
                            </p>
                          </div>
                        </td>
                        <td className="px-4">
                          {" "}
                          <div
                            className={
                              data?.status ? "success-badge" : "error-badge"
                            }
                          >
                            <p> {data?.status ? "Successful" : "Failed"}</p>
                          </div>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            )}
          </table>

          {bankTransactionsLoading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}
        </div>
      </div>

      {!bankTransactionsLoading && bankTransactionsData?.length > 0 ? (
        <div className="mt-1">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={allTransactionsTotal}
          />
        </div>
      ) : (
        ""
      )}

      {/* BANK TRANSACTION INFORMATION SECTION */}
      <section>
        <DrawerComponent
          show={isClickTable}
          showDrawer={() => setIsClickTable(false)}
          title="Transaction Info"
        >
          <div className="h-full">
            {transactionLoading ? (
              <div className="flex justify-center items-center h-full">
                {" "}
                <p>Loading</p>
              </div>
            ) : (
              <div className="mt-[40px]">
                {/* TRANSACTION ID */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Farmer Name
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                      {transactionData?.farmer?.first_name +
                        " " +
                        transactionData?.farmer?.last_name}
                    </p>
                    {/* <ContentCopy
                      className="text-[#5C715E] cursor-pointer"
                      style={{ fontSize: '14px' }}
                    /> */}
                  </div>
                </div>

                {/* TRANSACTION TYPE */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Phone Number
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                      {transactionData?.farmer?.phone_number}
                    </p>
                  </div>
                </div>

                {transactionData?.action === "transfer" ? (
                  <>
                    {/* TRANSACTION DATE */}
                    <div className="mb-[25px]">
                      <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                        Recipient Name
                      </p>
                      <div className="flex gap-[6px]">
                        <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                          {transactionData?.account_name ||
                            transactionsData?.account_name ||
                            "N/A"}
                        </p>
                      </div>
                    </div>

                    {/* TRANSACTION TIME */}
                    <div className="mb-[25px]">
                      <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                        Recipient Account Number
                      </p>
                      <div className="flex gap-[6px]">
                        <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                          {transactionData?.account_number ||
                            transactionsData?.account_number ||
                            "N/A"}
                        </p>
                      </div>
                    </div>

                    {/* TRANSACTION ACTIVITY */}
                    <div className="mb-[25px]">
                      <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                        Recipient Bank
                      </p>
                      <div className="flex gap-[6px]">
                        <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                          {transactionData?.bank_name ||
                            transactionsData?.bank_name ||
                            "N/A"}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}

                {/* TRANSACTION REFERENCE */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Reference
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                      {transactionData?.reference ||
                        transactionsData?.reference ||
                        "N/A"}
                    </p>
                  </div>
                </div>

                {/* TRANSACTION AMOUNT */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Amount
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-[#5c715e] text-sm leading-[18px]">
                      ₦
                      {transactionData?.amount ||
                        transactionsData?.amount ||
                        "N/A"}
                    </p>
                  </div>
                </div>

                {/* TRANSACTION  Amount Charged */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Amount Charged
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-[#5c715e] text-sm leading-[18px] ">
                      {transactionData?.amount_charged
                        ? "₦" + transactionData?.amount_charged
                        : " N/A"}
                    </p>
                  </div>
                </div>

                {/* TRANSACTION Previous Balance */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Date
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                      {transactionData?.created
                        ? moment(transactionData?.created).format("ll") || "N/A"
                        : moment(transactionsData?.created).format("ll") ||
                          "N/A"}
                    </p>
                  </div>
                </div>

                {/* TRANSACTION Available Balance */}
                <div className="mb-[25px]">
                  <p className="pb-[4px] font-[400] text-sm leading-[18px] text-[#5C715E]">
                    Time
                  </p>
                  <div className="flex gap-[6px]">
                    <p className="font-medium text-sm leading-[18px] text-[#5C715E]">
                      {transactionData?.created
                        ? moment(transactionData?.created).format("LT") || "N/A"
                        : moment(transactionsData?.created).format("LT") ||
                          "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </DrawerComponent>
      </section>
    </div>
  );
};

export default BankTransactionsTable;
