import { ContentCopy, ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import GoBack from "../../../components/GoBack";
import Header from "../../../components/Header";
import TableBackground from "../../../components/TableBackground";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getTemporaryFarmersInfo,
  updateTemporaryFarmersInfo,
} from "../../../redux/Farmers/farmersActions";
import cogoToast from "cogo-toast";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../components/Button/index";
import moment from "moment";
import ModalComponent from "../../../components/Modals/modal";
import {
  getMessageTemplate,
  sendSingleMessage,
} from "../../../redux/Messages/messageActions";

const TemporaryFarmersProfile = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { templateData } = useSelector((state) => state.message);
  ////////////////////////////////////////////// LANGUAGE
  const [toggleLanguageIcon, setToggleLanguageIcon] = useState(false);
  const [toggleTemplateIcon, setToggleTemplateIcon] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [message, setMessage] = useState("");
  const [firstName, setFirstName] = useState("First Name");
  const [lastName, setLastName] = useState("Last Name");
  const [summary, setSummary] = useState("");
  const [date, setDate] = useState();
  const [farmerInfo, setFarmerInfo] = useState({});

  const { id } = useParams();
  const { temporaryFarmersInfoLoading } = useSelector((state) => state.farmer);

  const languageData = [
    { id: "eng", name: "English" },
    { id: "yor", name: "Yoruba" },
    { id: "hau", name: "Hausa" },
  ];

  const language = [
    { key: "eng", label: "English" },
    { key: "yor", label: "Yoruba" },
    { key: "hau", label: "Hausa" },
  ];

  const status = [
    { key: "CALLED", label: "Called" },
    { key: "MISSED", label: "Missed" },
    { key: "TO_CALL", label: "Not Called" },
  ];

  const gender = [
    { key: "o", label: "Male" },
    { key: "f", label: "Female" },
  ];

  // HANDLES BOOLEAN CLICK EVENT
  const [clickState, updateClickState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isLanguageClick: false,
      isGenderClick: false,
      isStatusClick: false,
    }
  );

  // HANDLES SELECTED CLICK EVENT
  const [selectedValue, updateSelectedValue] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedLanguage: "",
      selectedGender: {},
      selectedStatus: "",
    }
  );

  // HANDLES GENDER CLICK
  const handleClickGender = (key, label) => {
    updateSelectedValue({ selectedGender: { key: key, label: label } });
    updateClickState({ isGenderClick: false });
  };

  // HANDLES CALL STATUS CLICK
  const handleClickStatus = (key, label) => {
    updateSelectedValue({ selectedStatus: { key: key, label: label } });
    updateClickState({ isStatusClick: false });
  };

  // HANDLES LANGUAGE CCLICK
  const handleClickLanguage = (key, label) => {
    updateSelectedValue({ selectedLanguage: { key: key, label: label } });
    updateClickState({ isLanguageClick: false });
  };

  const handleCopyNumberToClipboard = (number) => {
    navigator.clipboard.writeText(number);
    cogoToast.success(`Farmer's number (${number}) copied successfully`);
  };

  const fetchData = useCallback(async () => {
    const data = await getTemporaryFarmersInfo({ id: id }); // fetch data from your redux store
    dispatch(data)
      .then((result) => {
        let data = result?.payload?.data;
        setFirstName(data?.first_name);
        setLastName(data?.last_name);
        setSummary(data?.customer_service_conversation);
        setFarmerInfo(data);
        ////////////// USED TO FORMAT THE GENDER DATA TO KNOW WHAT TO DISPLAY AND SEND TO THE BACKEND
        if (data?.gender === "f") {
          updateSelectedValue({
            selectedGender: { key: "f", label: "Female" },
          });
        } else if (data?.gender === "m") {
          updateSelectedValue({
            selectedGender: { key: "m", label: "Male" },
          });
        } else {
          updateSelectedValue({
            selectedGender: { key: "others", label: "Select a gender" },
          });
        }

        ////////////// USED TO FORMAT THE CALL STATUS DATA TO KNOW WHAT TO DISPLAY AND SEND TO THE BACKEND
        if (data?.customer_service_status === "CALLED") {
          updateSelectedValue({
            selectedStatus: { key: "CALLED", label: "Called" },
          });
        } else if (data?.customer_service_status === "MISSED") {
          updateSelectedValue({
            selectedStatus: { key: "MISSED", label: "Missed" },
          });
        } else if (data?.customer_service_status === "TO_CALL") {
          updateSelectedValue({
            selectedStatus: { key: "TO_CALL", label: "To Call" },
          });
        }

        let year = data?.year;
        let day = data?.day;
        let month;

        /////Add one(0) if the month is less than 10 to fit html date input format in order to prefill the date of birth input field
        if (data?.month.length === 1) {
          month = "0" + data?.month;
        } else {
          month = data?.month;
        }

        if (year && month && day) {
          const formattedDate = `${year}-${month.padStart(
            2,
            "0"
          )}-${day.padStart(2, "0")}`;
          setDate(formattedDate);
          // console.log(`${year}-${month}-${day}`);
        } else {
          setDate(null);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
      });
  }, [dispatch, id]);

  const handleUpdateInfo = () => {
    // console.log(date);
    let year;
    let month;
    let day;
    if (date) {
      year = moment(date).year();
      month = moment(date).format("M");
      day = moment(date).format("D");
    } else {
      year = "";
      month = "";
      day = "";
    }

    const values = {
      customer_service_conversation: summary,
      customer_service_status: selectedValue.selectedStatus.key,
      day: day,
      first_name: firstName,
      gender: selectedValue.selectedGender.key,
      id: id,
      last_name: lastName,
      month: month,
      year: year,
    };

    dispatch(updateTemporaryFarmersInfo({ id: id, values }));

    setTimeout(() => {
      fetchData();
    }, 800);
  };

  const handleOpenLanguage = () => {
    if (Object.keys(selectedTemplate).length !== 0) {
      setToggleLanguageIcon(!toggleLanguageIcon);
    } else {
      cogoToast.error("Kindly select a template first");
    }
  };

  const handleSelectLanguage = (langData) => {
    let result = selectedTemplate.templates.filter(
      (data) => data.language === langData.name
    );
    setMessage(result.length !== 0 ? result[0].body : "");
    setSelectedLanguage(langData);
    setToggleLanguageIcon(false);
  };

  const handleSendMessage = () => {
    const value = {
      message,
      phone_numbers: [farmerInfo?.phone_number],
    };

    dispatch(sendSingleMessage({ value }))
      .then((res) => {
        if (res?.payload?.error) {
          cogoToast.error(res?.payload?.error);
          setShow(false);
        } else {
          setMessage("");
          setSelectedLanguage({});
          setSelectedTemplate({});
          cogoToast.success("Message sent successfully");
          setShow(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        }
        cogoToast.error(error?.response?.data.error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {temporaryFarmersInfoLoading ? (
        <div className="flex justify-center items-center h-[500px] w-full">
          {" "}
          <p className="font-medium text-sm leading-5">Loading...</p>
        </div>
      ) : (
        <div>
          {/* GO BACK SECTION */}
          <div>
            <GoBack />
          </div>

          {/* HEADER SECTION */}
          <div className="mt-[14px] mb-6">
            <Header text="Temporary Farmers Profile">
              <div className="relative">
                <div className="flex gap-[34px] items-center">
                  <div className="flex gap-[16px]">
                    <ButtonSecondary
                      onclick={() => {
                        setShow(true);
                        dispatch(getMessageTemplate({ page: 1 }));
                      }}
                    >
                      Send Message
                    </ButtonSecondary>
                  </div>
                </div>
              </div>
            </Header>
          </div>

          {/* FARMER"S PROFILE SECTION */}
          <section>
            <TableBackground classname="p-4" style={{ borderRadius: "8px" }}>
              <p className=" pb-6 text-sm leading-[18px] font-semibold text-[#7C8D7E]">
                Personal Details
              </p>
              <div className="flex gap-[20px]">
                <div className="w-1/3">
                  {/* FIRSTNAME */}
                  <div>
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      First Name
                    </p>
                    <input
                      onChange={(event) => setFirstName(event.target.value)}
                      placeholder="First Name"
                      value={firstName}
                      type="text"
                      className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    />
                  </div>

                  {/* Gender */}
                  <div className="relative">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Gender
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={() =>
                        updateClickState({
                          isGenderClick: !clickState.isGenderClick,
                        })
                      }
                    >
                      <p>
                        {selectedValue.selectedGender.label || "Select Gender"}
                      </p>

                      {clickState.isGenderClick ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {clickState.isGenderClick && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {gender.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() =>
                                handleClickGender(data.key, data.label)
                              }
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-1/3">
                  {/* LAST NAME */}
                  <div>
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Last Name
                    </p>
                    <input
                      type="text"
                      className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onChange={(event) => setLastName(event.target.value)}
                      placeholder="Last Name"
                      value={lastName}
                    />
                  </div>

                  {/* DATE OF BIRTH */}
                  <div>
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Date of Birth
                    </p>
                    <input
                      value={date}
                      onChange={(event) => setDate(event.target.value)}
                      type="date"
                      className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    />
                  </div>
                </div>
                <div className="w-1/3">
                  {/* PHONE NUMBER */}
                  <div>
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Phone Number
                    </p>
                    <div
                      className="flex items-center justify-between outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={() =>
                        handleCopyNumberToClipboard(farmerInfo?.phone_number)
                      }
                    >
                      <p>{farmerInfo?.phone_number} </p>

                      <ContentCopy
                        className="text-[#ABB6AC] cursor-pointer"
                        style={{ fontSize: "18px" }}
                      />
                    </div>
                  </div>

                  {/* LANGUAGE */}
                  <div className="relative">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Language
                    </p>
                    <div
                      className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={() =>
                        updateClickState({
                          isLanguageClick: !clickState.isLanguageClick,
                        })
                      }
                    >
                      <p>
                        {selectedValue.selectedLanguage.label ||
                          "Select Langauge"}
                      </p>

                      {clickState.isLanguageClick ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {clickState.isLanguageClick && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {language.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() =>
                                handleClickLanguage(data.key, data.label)
                              }
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </TableBackground>
          </section>

          <section className="mt-[24px]">
            <TableBackground classname="p-4" style={{ borderRadius: "8px" }}>
              <div className="flex gap-[20px]">
                <div className="w-[68%]">
                  {/* FIRSTNAME */}
                  <div>
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Summary
                    </p>
                    <textarea
                      value={summary}
                      onChange={(event) => setSummary(event.target.value)}
                      type="text"
                      className="h-[134px] outline-0 bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px] resize-none"
                      placeholder="Add Call Summary"
                    />
                  </div>
                </div>
                <div className="w-[32%]">
                  {/* CALL STATUS */}
                  <div className="relative">
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Call Status
                    </p>
                    <div
                      className="flex cursor-pointer items-center justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                      onClick={() =>
                        updateClickState({
                          isStatusClick: !clickState.isStatusClick,
                        })
                      }
                    >
                      <p>
                        {selectedValue.selectedStatus.label ||
                          "Select Call Status"}
                      </p>

                      {clickState.isStatusClick ? (
                        <ExpandLess
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      ) : (
                        <ExpandMore
                          className="text-[#ABB6AC] cursor-pointer"
                          style={{ fontSize: "18px" }}
                        />
                      )}
                    </div>

                    {clickState.isStatusClick && (
                      <div
                        className="absolute rounded-[8px] p-[8px] w-full bg-white z-10"
                        style={{
                          boxShadow:
                            "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                        }}
                      >
                        {status.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                              onClick={() =>
                                handleClickStatus(data.key, data.label)
                              }
                            >
                              <p className="font-medium text-sm leading-5 text-[#344335]">
                                {data.label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  {/* CALL DATE*/}
                  <div>
                    <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                      Call Date
                    </p>
                    <input
                      type="date"
                      className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[24px]"
                    />
                  </div>
                </div>
              </div>
            </TableBackground>
          </section>

          <div className="mt-6 flex flex-end justify-end">
            <ButtonPrimary onclick={handleUpdateInfo}>Save</ButtonPrimary>
          </div>

          {/* SEND MESSAGE MODAL */}
          <ModalComponent
            title="Compose Message"
            show={show}
            showModal={() => setShow(false)}
          >
            {/* MESSAGE TEMPLATE */}
            <div className="pt-[16px] pb-[10px]">
              <div className="relative w-full">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Message Template
                </p>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[6px]"
                  onClick={() => {
                    setToggleTemplateIcon(!toggleTemplateIcon);
                  }}
                >
                  <p>{selectedTemplate.title || "Select a template"}</p>

                  {toggleTemplateIcon ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {toggleTemplateIcon && (
                  <div
                    className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    {templateData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                          onClick={() => {
                            setSelectedTemplate(data);
                            setToggleTemplateIcon(false);
                          }}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {/* LANG */}
            <div className="pb-[10px]">
              <div className="relative w-full">
                <p className="font-[400] text-sm leading-[18px] text-[#344335]">
                  Select Language
                </p>
                <div
                  className="flex items-center cursor-pointer justify-between outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px]  px-[14px] w-full font-mdium text-sm leading-5 text-[#96A397] mb-[6px]"
                  onClick={handleOpenLanguage}
                >
                  <p>{selectedLanguage.name || "Language"}</p>

                  {toggleLanguageIcon ? (
                    <ExpandLess
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  ) : (
                    <ExpandMore
                      className="text-[#ABB6AC] cursor-pointer"
                      style={{ fontSize: "18px" }}
                    />
                  )}
                </div>

                {toggleLanguageIcon && (
                  <div
                    className="mb-4 rounded-[8px] p-[8px] w-full bg-white z-20 max-h-[250px] overflow-y-scroll "
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                    }}
                  >
                    {languageData.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer flex gap-4"
                          onClick={() => handleSelectLanguage(data)}
                        >
                          <p className="font-medium text-sm leading-5 text-[#344335]">
                            {data.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            {/* MESSAGE */}
            <div className="pb-[10px]">
              <textarea
                className="resize-none h-[134px] disabled:bg-[#F7F7F7] outline-none border bg-white border-solid border-[#96A397] rounded-[8px] font-medium text-sm leading-5 text-[#96A397] py-[12px] px-[16px] mt-1 w-full "
                placeholder="Message"
                onChange={(event) => setMessage(event.target.value)}
                value={message}
              />
            </div>

            <div className="flex items-center justify-end gap-[24px] my-6">
              <button
                type="submit"
                disabled={message.length !== 0 ? false : true}
                onClick={message && handleSendMessage}
                className="bg-primary 
                            disabled:bg-[#ABB6AC] 
                            rounded-lg px-6 py-3 text-white text-sm font-medium hover:bg-[#24752B] transition-all"
              >
                Send
              </button>
            </div>
          </ModalComponent>
        </div>
      )}
    </>
  );
};

export default TemporaryFarmersProfile;
