import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardWithoutSeeMore } from "../../components/Cards";
import Header from "../../components/Header";
import { getAgentsStats } from "../../redux/Agents/agentsAction";
import AgentsTable from "../../tables/Agents";

const Agents = () => {
  const dispatch = useDispatch();
  const { agentsStats } = useSelector((state) => state.agents);

  useEffect(() => {
    dispatch(getAgentsStats());
  }, [dispatch]);

  console.log(agentsStats);

  const cardsData = [
    {
      title: "Pending Agent Approval",
      subtitle: ` ${agentsStats?.no_of_pending_agents || "0"}`,
      href: "/pending-agent-approval",
    },
    {
      title: "Total Agents",
      subtitle: ` ${agentsStats?.total || "0"}`,
    },
    {
      title: "Flagged Accounts",
      subtitle: ` ${agentsStats?.no_of_flagged_agents || "0"}`,
      href: "/flagged-agents",
    },
  ];
  return (
    <div>
      <Header text="Agents" />
      {/* CARDS SECTION */}
      <section>
        <div className="grid grid-cols-4 gap-6 mt-6 mb-8">
          {cardsData.map((item, index) => {
            return (
              <>
                {item.href ? (
                  <Card
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                    href={item.href}
                  />
                ) : (
                  <CardWithoutSeeMore
                    key={index}
                    title={item.title}
                    subtitle={item.subtitle}
                    description={item.description}
                  />
                )}
              </>
            );
          })}
        </div>
      </section>

      {/* TABLE SECTION */}
      <section>
        <AgentsTable />
      </section>
    </div>
  );
};

export default Agents;
