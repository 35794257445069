import React, { useEffect, useState } from "react";
import PaginationComponent from "../../components/Pagination/Pagination";
import SearchComponent from "../../components/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVendorsStats } from "../../redux/Orders/ordersAction";

const VendorsTable = () => {
  const dispatch = useDispatch();
  const { vendorStatsData, vendorStatsLoading, vendorStatsTotal } = useSelector(
    (state) => state.orders
  );
  const navigate = useNavigate();
  const [typeID, setTypeID] = useState(1);
  const [typeTitle, setTypeTitle] = useState("All");
  const orderTypes = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Crop Input",
    },
    {
      id: 3,
      title: "Livestock Input",
    },
    {
      id: 4,
      title: "Mechanization",
    },
    {
      id: 5,
      title: "Advisory",
    },
    {
      id: 6,
      title: "Insurance",
    },
  ];

  const [searchValue, setSearchValue] = useState("");
  const handleChange = (e) => {
    let val = e.target.value;
    setSearchValue(val);
  };

  const toggleType = (id, title) => {
    setTypeID(id);
    setTypeTitle(title);
    dispatch(getVendorsStats({ page: 1, orderType: title }));
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    dispatch(getVendorsStats({ page: page, orderType: typeTitle }));
  };

  const viewOrder = (id, data) => {
    sessionStorage.setItem("vendorName", data?.display_name);

    navigate(`/orders/vendor/${id}/${typeTitle}`);
  };

  console.log(vendorStatsData);

  useEffect(() => {
    dispatch(getVendorsStats({ page: 1, orderType: "All" }));
  }, [dispatch]);

  return (
    <>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          <div className="w-full">
            <SearchComponent
              placeholder="Search"
              searchValue={searchValue}
              handleChange={handleChange}
            />
          </div>

          <ul className="flex mt-5 gap-5 items-center">
            {orderTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.title)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
                <span
                  className={
                    typeID === item.id
                      ? "bg-[#DBFFDF] rounded-[4px] py-1 px-1 text-primary font-semibold text-[10px]"
                      : "bg-[#ECEEEC] rounded-[4px] py-1 px-1 text-[#96A397] font-semibold text-[10px]"
                  }
                >
                  {item.id === typeID ? vendorStatsTotal : "0"}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          {vendorStatsLoading ? (
            <div className="animate-pulse w-full mt-4 mb-4 px-4 pb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          ) : (
            <table className="w-full text-left">
              <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                <tr>
                  <th scope="col" className="px-4 py-4 font-semibold ">
                    Vendor Name
                  </th>

                  {typeTitle === "All" && (
                    <th scope="col" className="px-4 py-4 font-semibold ">
                      Category
                    </th>
                  )}

                  <th scope="col" className="px-4 py-4 font-semibold">
                    Delivered Orders
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Undelivered Orders
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Orders in Progress
                  </th>
                  <th scope="col" className="px-4 py-4 font-semibold">
                    Orders in Transit
                  </th>
                </tr>
              </thead>
              <tbody>
                {[Object.values(vendorStatsData)]?.map((data) => {
                  return (
                    <>
                      {data.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={
                              data?.id && (() => viewOrder(data?.id, data))
                            }
                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                          >
                            <th
                              scope="row"
                              className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                            >
                              {data?.name || data?.display_name || "N/A"}
                            </th>
                            {typeTitle === "All" && (
                              <td className="py-4 px-4 text-[#5C715E]">
                                {" "}
                                {data?.vendor_types
                                  ?.map((category) => category)
                                  .join(", ")}
                              </td>
                            )}
                            <td className="py-4 px-4 text-[#5C715E]">
                              <div className="flex">
                                <div className="success-badge">
                                  {data?.delivered}
                                </div>
                              </div>
                            </td>
                            <td className="py-4 px-4 text-[#5C715E]">
                              <div className="flex">
                                <div className="error-badge">
                                  {data?.undelivered}
                                </div>
                              </div>
                            </td>
                            <td className="py-4 px-4">
                              <div className="flex">
                                <div className="pending-badge">
                                  {data?.pending}
                                </div>
                              </div>
                            </td>
                            <td className="py-4 px-4">
                              <div className="flex">
                                <div className="info-badge">0</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="mt-1">
        <PaginationComponent
          page={currentPage}
          onPageChange={(page) => onPageChange({ page })}
          pageSize={10}
          totalItems={vendorStatsTotal}
        />
      </div>
    </>
  );
};

export default VendorsTable;
